<template>
	<div class="Brand">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" @click="$Jump('/my/shop/brand_info/add')">添加品牌</el-button>
			</div>
			<div class="Right">
				<span style="margin-left: 10px;">
					<label>状态</label>
					<el-select v-model="Search.Status" size="mini">
						<el-option :value="''" label="全部状态"></el-option>
						<el-option :value="50" label="启用中"></el-option>
						<el-option :value="10" label="禁用"></el-option>
					</el-select>
				</span>
				<span style="margin-left: 10px;margin-right: 10px;">
					<label>品牌Id</label>
					<el-input size="mini" v-model="Search.Id"></el-input>
				</span>
				<span style="margin-left: 10px;">
					<label>关键词</label>
					<el-input size="mini" v-model="Search.Name"></el-input>
				</span>
				
				<span>
					<label style="color: rgba(0,0,0,0);">搜索</label>
					<el-button size="mini" type="primary" @click="StartSearching()">搜索</el-button>
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="Title">
				<div class="C1">品牌名</div>
				<div class="C2">图标</div>
				<div class="C3">品牌状态</div>
				<div class="C4">创建时间</div>
				<div class="C5">品牌ID</div>
				<div class="C6">操作</div>
			</div>
			
			<div class="One" v-for="item in BrandList" :key="item.Id">
				<li>
					<div class="C1">{{item.Name}}</div>
					<div class="C2">
						
						<img v-if="item.Logo != ''" :src="item.Logo" />
						
					</div>
					<div class="C3">{{item.StatusName}}</div>
					<div class="C4">{{item.CreatedAt}}</div>
					<div class="C5">{{item.Id}}</div>
					<div class="C6">
						<el-button size="mini" @click="$Jump('/my/shop/brand_info/'+item.Id)">查看&管理</el-button>
					</div>
				</li>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'BrandList',
	  props: {
	  },
	  data() {
	      return {
			  Search:{
				Id:'',
				 Name:'',
				 Status:'',
			  },
			  BrandList:[],
			  Page:1,
			  PageSize:15,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.GetList(1)
	  },
	  methods:{
		  handleCurrentChange(val){
			 this.GetList(val)
		  },
		  StartSearching(){
			  this.GetList(this.Page)
			  this.GetCount()
		  },
		  GetList(_page){
		  		let data = {
		  			Service:'Brand',
		  			Class: 'Brand',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
					Id:this.Search.Id,
					Name:this.Search.Name,
					Status:this.Search.Status,
					IsSeller:true,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					this.BrandList = res.Data.BrandList
					this.Page = res.Data.CurrentPage
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCount(){
		  		let data = {
		  			Service:'Brand',
		  			Class: 'Brand',
		  			Action: 'Count',
					Id:this.Search.Id,
					Name:this.Search.Name,
					Status:this.Search.Status,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			this.Total = res.Data.Count
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  }
	  }
	}
</script>

<style scoped>
.Brand{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Brand .Top{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.05);
	padding-bottom: 20px;
}
.Brand .Top .Left{
	
}
.Brand .Top .Right{
	flex: 1;
	display: flex;
	color: #999999;
	justify-content: flex-end;
}
.Brand .Top .Right label{
	display: block;
	font-size: 0.9rem;
	margin-bottom: 5px;
	color: rgba(0,0,0,0.4);
}
.Brand .List{
}
.C1,.C2,.C3,.C4,.C5{
	width: 150px;
}
.C1{
	padding-left: 10px;
}
.C2 img{
	height: 25px;
}
.C5{
	flex: 1;
	color: rgba(0,0,0,0.25);
}
.C6{
	padding-right: 10px;
	text-align: right;
}
.Brand .List .Title{
	display: flex;
	margin-bottom: 20px;
	color: rgba(0,0,0,0.3);
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	font-size: 0.9rem;
}
.Brand .List .One{
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 10px 0px;
}
.Brand .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.Brand .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
</style>
